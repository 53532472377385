import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Footer = () => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            settings: datoCmsSetting {
                email
                donatePrompt
                accountNumber
                vippsPrompt
                vippsNumber
            }
        }
    `)
    return (
        <footer className="blue">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Kontakt</h2>
                        Parkgata 14, 6003 Ålesund
                        <br />
                        {data.settings.email}
                    </div>
                    <div className="col-md-6 support">
                        <h2>Vil du støtte oss?</h2>
                        <p>
                            {data.settings.donatePrompt}:{" "}
                            {data.settings.accountNumber}
                        </p>
                        <p>
                            {data.settings.vippsPrompt}:{" "}
                            {data.settings.vippsNumber}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
