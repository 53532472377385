import React from "react"

const Banner = ({ header, subheader, content }) => (
    <section className="banner red">
        <div className="container">
            <div className="row banner">
                <div className="col-md-12">
                    <h1>{header}</h1>
                    <h2>{subheader}</h2>
                    <p>{content}</p>
                </div>
            </div>
        </div>
    </section>
)

export default Banner
