import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const Navbar = () => {
    const data = useStaticQuery(graphql`
        query NavbarQuery {
            pages: allDatoCmsPage(sort: { fields: sort, order: ASC }) {
                nodes {
                    title
                    url
                }
            }
        }
    `)
    return (
        <nav className="navbar navbar-default navbar-static-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        aria-controls="navbar"
                        aria-expanded="false"
                        className="navbar-toggle collapsed"
                        data-target="#navbar"
                        data-toggle="collapse"
                        type="button"
                    >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />
                    </button>
                    <Link className="navbar-brand logo" to="/">
                        <img
                            src="/logo_small.png"
                            height="60px"
                            width={126}
                            alt="Logo"
                        />
                    </Link>
                </div>
                <div className="navbar-collapse collapse" id="navbar">
                    <ul className="nav navbar-nav">
                        {data.pages.nodes.map(link => (
                            <li>
                                <Link to={link.url}>{link.title}</Link>
                            </li>
                        ))}
                    </ul>
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a className="logo" href="https://www.kfuk-kfum.no">
                                <img
                                    src="/logo_kfukkfum.png"
                                    height="60px"
                                    alt="Logo KFUK-KFUM"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
