import React from "react"
import { graphql, Link } from "gatsby"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import Banner from "../components/banner"
import "../styles/frontpage.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const frontPage = ({ data: { frontpage, settings } }) => {
    const image = getImage(frontpage.coverphoto)
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: "nb",
                }}
            >
                <title>Ålesund KFUK-KFUM</title>
                <meta name="description" content="Ålesund KFUK-KFUM" />
            </Helmet>
            <Navbar />
            <div className="row header">
                <div className="row-md-12">
                    <GatsbyImage
                        alt="Forsidebilde"
                        image={image}
                        loading="eager"
                    />
                </div>
            </div>
            <Banner
                header={frontpage.banner[0].header}
                subheader={frontpage.banner[0].subheader}
                content={frontpage.banner[0].content}
            />
            <div className="container info">
                <div className="row">
                    <div className="col-sm-6">
                        <h2>Plasser</h2>
                        <h3>Ynglingen</h3>
                        {frontpage.ynglingen}
                        {settings.leietekst && (
                            <p>
                                <Link to="/utleie/ynglingen">
                                    {settings.leietekst}
                                </Link>
                            </p>
                        )}
                        <h3>Dyrøya</h3>
                        {frontpage.dyroya}
                        {settings.leietekst && (
                            <p>
                                <Link to="/utleie/dyroya">
                                    {settings.leietekst}
                                </Link>
                            </p>
                        )}
                    </div>
                    <div className="col-sm-6">
                        <h2>Nyheter</h2>
                        <iframe
                            title="Facebook"
                            src={`https://www.facebook.com/plugins/page.php?href=${settings.facebookLink}&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                            width="400"
                            height="500"
                            style={{ border: "none", overflow: "hidden" }}
                            scrolling="no"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        />
                    </div>
                </div>
            </div>
            <iframe
                title="Map"
                className="map"
                width="auto"
                height="600"
                frameBorder="0"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDoYa5q4xbXFVtscc5-EoORg_5KJ3vQVPk&q=Parkgata+14,+6003+Ålesund&zoom=15"
                allowFullScreen
            />
            <Footer />
        </>
    )
}

export default frontPage

export const query = graphql`
    query FrontpageQuery {
        frontpage: datoCmsFrontpage {
            ynglingen
            dyroya
            coverphoto {
                gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            banner {
                header
                subheader
                content
            }
        }
        settings: datoCmsSetting {
            leietekst
            facebookLink
        }
    }
`
